import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "suppliers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData   

    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editar(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData   

    var url = ConfigAPI.baseURL + "suppliers/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "suppliers/"+ id + Session.getToken();
    return instance.delete(url);
  },
  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },
  crearUsuario(data) {
    var params = {
      name: data.name,
      email: data.email,
      password: data.password,
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "suppliers/createUser/" + data.supplier_id + Session.getToken();
    return instance.post(url,params);
  },
  editarUsuarioRoles(data) {
    var params = {
      user_id: data.user_id,
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "suppliers/editUserRoles/" + data.supplier_id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarUsuario(data) {
    var params = {
      user_id: data.user_id,
    }

    var url = ConfigAPI.baseURL + "suppliers/destroyUser/"+ data.supplier_id + Session.getToken();
    return instance.post(url,params);
  },
  seleccionarUsuario(data) {
    var params = {
      email: data.email,
    }
    var url = ConfigAPI.baseURL + "suppliers/selectUser/"+ data.supplier_id + Session.getToken();
    return instance.post(url,params);
  },
  vincularUsuario(data){
    var params = {
      user_id: data.user_id,
    }
    var url = ConfigAPI.baseURL + "suppliers/attachUser/" + data.supplier_id + Session.getToken();
    return instance.post(url,params);
  },
  filtrarProveedor(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "suppliers/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  agregarSalesSuppliers(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-suppliers" + Session.getToken();
    return instance.post(url,params);
  },
  editarSalesSuppliers(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-suppliers/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  importarProveedores(data, file) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "suppliers/import/CSV" + Session.getToken();
    
    return instance.post(url,params,{headers});
  },
  
  filtrarComprobanteById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase/filter/queryById" + Session.getToken();
    return instance.post(url,params);
  },  

  filtrarCurrentsAccounts(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  filtrarCurrentsAccountsPending(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query/pending" + Session.getToken();
    return instance.post(url,params);
  },    
  filtrarCurrentsAccountsInvoice(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query/invoice" + Session.getToken();
    return instance.post(url,params);
  },  

  agregarCuentaContable(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts-suppliers" + Session.getToken();
    return instance.post(url,params);
  },
  editarCuentaContable(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts-suppliers/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCuentaContable(id) {    
    var url = ConfigAPI.baseURL + "accounting-accounts-suppliers/" + id + Session.getToken();
    return instance.delete(url);
  },
}

export default servicesAPI;
